import React from "react";
import HeaderWidget from "../widgets/HeaderWidget";
import backgroundImage from "../assets/images/background-image.jpg";
import FooterWidget from "../widgets/FooterWidget";
import PartnerWidget from "../widgets/PartnerWidget";

function InfoScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto mt-5 md:w-max w-full  mb-5 min-h-[70vh] flex flex-col items-center justify-center ">
            <div className="bg-white flex flex-col items-center justify-center px-5 py-10 mt-5 md:w-2/3 w-full mb-5  ">
              {/* <p className="text-center  font-medium text-2xl p-3   w-max px-3 mx-auto mt-4 md:mb-4 mb-2">
                <a
                  href="/costa-glodex-book-award-info.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-[#a15656] px-5 py-3 rounded shadow md:text-xl text-sm font-bold text-white"
                >
                  Download info about the award
                </a>
              </p> */}
              {/* <p className="text-center font-medium  p-3 text-2xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                <a
                  href="/costa-glodex-book-award-entry-form.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-[#a15656] px-5 py-3 rounded shadow  md:text-xl text-sm font-bold  text-white "
                >
                  Download entry form
                </a>
              </p> */}
              <div className="  my-2  mx-auto">
                <p className=" text-center text-xl my-10 mx-auto w-full font-bold">
                  Costa Goldex Book Award Update September 2024
                </p>
                <p className="my-3  mx-auto w-full ">
                  Costa Goldex and the Marrakech English Book Association (MEBA)
                  are delighted to announce that entries are now closed for the
                  inaugural Costa Goldex Book Award, the brand-new annual book
                  award open to authors in Morocco who write and are published
                  in English.
                </p>
                <p className="my-3  mx-auto w-full ">
                  We have received an encouraging number of entries by Moroccan
                  authors covering a range of genres.
                </p>
                <p className="my-3  mx-auto w-full ">
                  We are also very pleased that entries have come not only from
                  established authors, with international publishers, but also
                  from first time authors sending in their self-published books.
                </p>
                <p className="my-3  mx-auto w-full ">
                  All the entries have now been passed onto our international
                  panel of judges, who are all professionals in the book
                  industry representing editors, publishers and book retailers.
                </p>
                <p className="my-3  mx-auto w-full ">
                  The judges will release a short list of the 3 finalists at the
                  beginning of November and we will announce the winner of the
                  2024 Award at the beginning of December.
                </p>
                <p className="my-3  mx-auto w-full ">
                  The winning author will be an official guest at the next
                  edition of the Marrakech English Book Festival (MEBF), on the
                  weekend of 17th to 19th January 2025, and will be presented
                  with his prize by Diljit Brar, CEO of the Goldex Group and
                  Yassin Adnan, President of the MEBF, at a special award
                  ceremony in Marrakech during the festival. The festival will
                  also mark the official opening of entries for the 2025 Costa
                  Goldex Book Award.
                </p>
                <p className="my-3  mx-auto w-full ">
                  We hope that this first year of the award, will encourage more
                  Moroccans to write and publish in English and we look forward
                  to receiving a new selection of entries for the 2025 award.
                </p>
                <p className="my-3  mx-auto w-full ">
                  Costa Goldex and the Marrakech English Book Association are
                  committed to supporting and encouraging the spread of English
                  and education throughout Morocco. We do this not only through
                  our partnership in the organization of the Marrakech English
                  Book Festival, but also through our active support of British
                  participation in Morocco’s annual international book
                  festivals, SILEJ in Casablanca and SIEL in Rabat.
                </p>
              </div>
              {/* <p className="text-center font-medium md:text-2xl text-xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                Send 3 hard copies of your book to:
                <br />
                <div className="text-base  ">
                  Marrakech English Book Association C/O Still Images <br />
                  Résidence Achraf
                  <br /> Appt 10 10 Rue Ibn Atya
                  <br /> Guéliz Marrakech 40020 Morocco
                </div>
              </p> */}
              {/* <p className="text-center font-medium md:text-2xl text-xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                Email your completed entry form + pdf copy of your book to:
                <br />
                <a
                  className="text-[#a15656]"
                  target={"_blank"}
                  rel="noreferrer"
                  href="mailto:admin@mebookfest.com"
                >
                  admin@mebookfest.com
                </a>
              </p> */}
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default InfoScreen;

import React, { useState } from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";

import image01 from "../assets/images/gallerys/MEBF0001.jpeg";
import image02 from "../assets/images/gallerys/MEBF0002.jpeg";
import image03 from "../assets/images/gallerys/MEBF0003.jpeg";
import image04 from "../assets/images/gallerys/MEBF0004.jpeg";
import image05 from "../assets/images/gallerys/MEBF0005.jpeg";
import image06 from "../assets/images/gallerys/MEBF0060.jpeg";
import image07 from "../assets/images/gallerys/MEBF0007.jpeg";
import image08 from "../assets/images/gallerys/MEBF0008.jpeg";
import image09 from "../assets/images/gallerys/MEBF0009.jpeg";
import image10 from "../assets/images/gallerys/MEBF0010.jpeg";
import image11 from "../assets/images/gallerys/MEBF0011.jpeg";
import image12 from "../assets/images/gallerys/MEBF0012.jpeg";
import image13 from "../assets/images/gallerys/MEBF0013.jpeg";
import image14 from "../assets/images/gallerys/MEBF0041.jpeg";
import image15 from "../assets/images/gallerys/MEBF0015.jpeg";
import image16 from "../assets/images/gallerys/MEBF0016.jpeg";
import image17 from "../assets/images/gallerys/MEBF0017.jpeg";
import image18 from "../assets/images/gallerys/MEBF0018.jpeg";
import image19 from "../assets/images/gallerys/MEBF0019.jpeg";
import image20 from "../assets/images/gallerys/MEBF0020.jpeg";
import image21 from "../assets/images/gallerys/MEBF0021.jpeg";
import image22 from "../assets/images/gallerys/MEBF0022.jpeg";
import image23 from "../assets/images/gallerys/MEBF0023.jpeg";
import image24 from "../assets/images/gallerys/MEBF0021.jpeg";
import image25 from "../assets/images/gallerys/MEBF0025.jpeg";
import image26 from "../assets/images/gallerys/MEBF0026.jpeg";
import image27 from "../assets/images/gallerys/MEBF0027.jpeg";
import image28 from "../assets/images/gallerys/MEBF0028.jpeg";
import image29 from "../assets/images/gallerys/MEBF0029.jpeg";
import image30 from "../assets/images/gallerys/MEBF0030.jpeg";
import image31 from "../assets/images/gallerys/MEBF0031.jpeg";
import image32 from "../assets/images/gallerys/MEBF0032.jpeg";
import image33 from "../assets/images/gallerys/MEBF0033.jpeg";
import image34 from "../assets/images/gallerys/MEBF0031.jpeg";
import image35 from "../assets/images/gallerys/MEBF0035.jpeg";
import image36 from "../assets/images/gallerys/MEBF0036.jpeg";
import image37 from "../assets/images/gallerys/MEBF0037.jpeg";
import image38 from "../assets/images/gallerys/MEBF0038.jpeg";
import image39 from "../assets/images/gallerys/MEBF0039.jpeg";
import image40 from "../assets/images/gallerys/MEBF0040.jpeg";
import image41 from "../assets/images/gallerys/MEBF0041.jpeg";
import image42 from "../assets/images/gallerys/MEBF0042.jpeg";
import image43 from "../assets/images/gallerys/MEBF0043.jpeg";
import image44 from "../assets/images/gallerys/MEBF0041.jpeg";
import image45 from "../assets/images/gallerys/MEBF0045.jpeg";
import image46 from "../assets/images/gallerys/MEBF0046.jpeg";
import image47 from "../assets/images/gallerys/MEBF0047.jpeg";
import image48 from "../assets/images/gallerys/MEBF0048.jpeg";
import image49 from "../assets/images/gallerys/MEBF0049.jpeg";
import image50 from "../assets/images/gallerys/MEBF0050.jpeg";
import image51 from "../assets/images/gallerys/MEBF0051.jpeg";
import image52 from "../assets/images/gallerys/MEBF0052.jpeg";
import image53 from "../assets/images/gallerys/MEBF0053.jpeg";
import image54 from "../assets/images/gallerys/MEBF0051.jpeg";
import image55 from "../assets/images/gallerys/MEBF0055.jpeg";
import image56 from "../assets/images/gallerys/MEBF0056.jpeg";
import image57 from "../assets/images/gallerys/MEBF0057.jpeg";
import image58 from "../assets/images/gallerys/MEBF0058.jpeg";
import image59 from "../assets/images/gallerys/MEBF0059.jpeg";
import image60 from "../assets/images/gallerys/MEBF0060.jpeg";

const photos = [
  // { src: image01, index: 1, width: 800, height: 600 },
  { src: image02, index: 2, width: 1000, height: 900 },
  { src: image03, index: 3, width: 800, height: 600 },
  { src: image04, index: 4, width: 1000, height: 900 },
  { src: image05, index: 5, width: 800, height: 600 },
  { src: image06, index: 6, width: 1000, height: 900 },
  { src: image07, index: 7, width: 800, height: 600 },
  { src: image08, index: 8, width: 1000, height: 900 },
  { src: image09, index: 9, width: 800, height: 600 },
  { src: image10, index: 10, width: 1000, height: 900 },
  { src: image11, index: 11, width: 800, height: 600 },
  // { src: image12, index: 12, width: 1000, height: 900 },
  { src: image13, index: 13, width: 800, height: 600 },
  { src: image14, index: 14, width: 1000, height: 900 },
  { src: image15, index: 15, width: 800, height: 600 },
  // { src: image16, index: 16, width: 1000, height: 900 },
  { src: image17, index: 17, width: 800, height: 600 },
  { src: image18, index: 18, width: 1000, height: 900 },
  // { src: image19, index: 19, width: 800, height: 600 },
  { src: image20, index: 20, width: 1000, height: 900 },
  { src: image21, index: 21, width: 800, height: 600 },
  { src: image22, index: 22, width: 1000, height: 900 },
  { src: image23, index: 23, width: 800, height: 600 },
  { src: image24, index: 24, width: 1000, height: 900 },
  { src: image25, index: 25, width: 800, height: 600 },
  { src: image26, index: 26, width: 1000, height: 900 },
  { src: image27, index: 27, width: 800, height: 600 },
  { src: image28, index: 28, width: 1000, height: 900 },
  { src: image29, index: 29, width: 800, height: 600 },
  { src: image30, index: 30, width: 1000, height: 900 },
  // { src: image31, index: 31, width: 800, height: 600 },
  { src: image32, index: 32, width: 1000, height: 900 },
  { src: image33, index: 33, width: 800, height: 600 },
  { src: image34, index: 34, width: 1000, height: 900 },
  { src: image35, index: 35, width: 800, height: 600 },
  { src: image36, index: 36, width: 1000, height: 900 },
  { src: image37, index: 37, width: 800, height: 600 },
  { src: image38, index: 38, width: 1000, height: 900 },
  { src: image39, index: 39, width: 800, height: 600 },
  { src: image40, index: 40, width: 1000, height: 900 },
  // { src: image41, index: 41, width: 800, height: 600 },
  { src: image42, index: 42, width: 1000, height: 900 },
  // { src: image43, index: 43, width: 800, height: 600 },
  { src: image44, index: 44, width: 1000, height: 900 },
  { src: image45, index: 45, width: 800, height: 600 },
  { src: image46, index: 46, width: 1000, height: 900 },
  { src: image47, index: 47, width: 800, height: 600 },
  // { src: image48, index: 48, width: 1000, height: 900 },
  { src: image49, index: 49, width: 800, height: 600 },
  { src: image50, index: 50, width: 1000, height: 900 },
  { src: image51, index: 51, width: 800, height: 600 },
  { src: image52, index: 52, width: 1000, height: 900 },
  { src: image53, index: 53, width: 800, height: 600 },
  { src: image54, index: 54, width: 1000, height: 900 },
  { src: image55, index: 55, width: 800, height: 600 },
  { src: image56, index: 56, width: 1000, height: 900 },
  { src: image57, index: 57, width: 800, height: 600 },
  { src: image58, index: 58, width: 1000, height: 900 },
  { src: image59, index: 59, width: 800, height: 600 },
  { src: image60, index: 60, width: 1000, height: 900 },
];

function PhotoGalleryScreen() {
  const [selectImage, setSelectImage] = useState("");
  const [indexImage, setIndexImage] = useState(-1);
  const [isShow, setIsShow] = useState(false);
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container md:mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white">
            <div className="masonry-grid">
              {photos.map((photo, index) => (
                <div key={index} className="masonry-item">
                  <img
                    onClick={() => {
                      setSelectImage(photo);
                      setIndexImage(index);
                      setIsShow(true);
                    }}
                    src={photo.src}
                    alt={`Image ${index + 1}`}
                    className="w-full h-auto cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {isShow ? (
          <div className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 z-50 cursor-pointer">
            <div
              onClick={() => {
                setIsShow(false);
                setSelectImage("");
                setIndexImage(-1);
              }}
              className=" absolute right-10 top-10 z-[60] cursor-pointer text-xl text-white rounded-full bg-black shadow-xl p-3 w-11 h-11 flex flex-col items-center justify-center"
            >
              X
            </div>
            <div className="container mx-auto h-screen">
              <div
                id="gallery"
                className="relative w-full h-screen"
                data-carousel="slide"
              >
                <div className="relative h-screen overflow-hidden rounded-lg ">
                  <div className=" duration-700 ease-in-out" data-carousel-item>
                    <img
                      src={selectImage.src}
                      className="absolute block max-w-full h-screen -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 p-3 rounded-md"
                      alt="selectImage.src"
                    />
                  </div>
                </div>
                <button
                  onClick={() => {
                    if (indexImage === 0) {
                      setIndexImage(photos.length - 1);
                      setSelectImage(photos[photos.length - 1]);
                    } else {
                      setIndexImage(indexImage - 1);
                      setSelectImage(photos[indexImage - 1]);
                    }
                  }}
                  type="button"
                  className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  data-carousel-prev
                >
                  <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg
                      className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 1 1 5l4 4"
                      />
                    </svg>
                    <span className="sr-only">Previous</span>
                  </span>
                </button>
                <button
                  onClick={() => {
                    if (indexImage === photos.length - 1) {
                      setIndexImage(0);
                      setSelectImage(photos[0]);
                    } else {
                      setIndexImage(indexImage + 1);
                      setSelectImage(photos[indexImage + 1]);
                    }
                  }}
                  type="button"
                  className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  data-carousel-next
                >
                  <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg
                      className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <span className="sr-only">Next</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default PhotoGalleryScreen;

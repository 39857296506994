import React from "react";

function FooterWidget() {
  return (
    <div className="bg-black w-screen z-20">
      <div className="container mx-auto py-5 px-5 text-white ">
        <div className="w-full flex flex-row items-center text-center md:text-sm text-xs">
          The Marrakech English Book Festival is organized by the Marrakech
          English Book Association and is co-hosted with our venue partner
          Moroccan Foundation Nationale des Musées and our accommodation
          partner, the JAAL Riad Resort Marrakech, with the support of our
          corporate sponsors Travel Link and Aynnaka. The Costa Goldex Book
          Award & short Story Competition is organized and supported by our
          partners Goldex Morocco and the Olveseed Foundation. The MEBF is also
          closely supported by our education partners The British Academy School
          Marrakech, the Cadi Ayyad and UM6P Universities.
        </div>
      </div>
    </div>
  );
}

export default FooterWidget;

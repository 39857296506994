import React from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";

function CreditScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="container mx-auto px-5 min-h-[70vh] flex-1 z-20"></div>
        {/*  */}

        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default CreditScreen;

import React from "react";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";

function AboutScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")", }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container md:mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white">
            <h1 className="my-3 md:text-3xl text-xl font-bold mb-5">
              The Marrakech English Book Festival
            </h1>
            <p className="my-5 md:text-base text-sm">
              The festival is organized by the Marrakech English Book
              Association, under the Presidency of Mr Yassin Adnan, and is
              co-hosted by the Moroccan Fondation Nationale des Musées and The
              JAAL Riad Resort Marrakech. The festival is only possible through
              the support of our corporate sponsors Travel Link Morocco and
              Aynnaka.
            </p>
            <p className="my-5 md:text-base text-sm">
              The program of children’s events is made possible by the support
              of our partner the British Academy School Marrakech.
            </p>
            <p className="my-5 md:text-base text-sm">
              The MEBF is a celebration of Marrakech and Morocco as an
              inspiration for all genres of books published in the English
              language. This second edition of the MEBF will also inaugurate a
              new National Book Prize, The Costa Goldex Book Award, which will
              be awarded at a ceremony during the festival alongside the
              National Short Story Prize organised by the Morocco Libraires
              Project.
            </p>
            <p className="my-5 md:text-base text-sm">
              The content of the festival is guided by a small independent
              Literary Advisory Committee.
            </p>
            <p className="my-5 md:text-base text-sm">
              The festival comprises of a series of events, ranging from
              discussions and interviews with invited authors, children’s book
              events (including exhibitions, readings, and illustration
              workshops. The language of the festival is English.
            </p>
            <p className="my-5 md:text-base text-sm">
              The core festival events will be held over three days on the
              weekend of 17th to 19th January 2025. Most of these will be held
              at the national Museums Des Confluences Dar El Bacha and Jamaâ
              El-Fna in the Medina, chosen for their architectural, historical
              and cultural significance in Marrakech.
            </p>
            <p className="my-5 md:text-base text-sm">
              A children’s day of events will be held at the British Academy
              School in Targa and at the Musée des Confluences.
            </p>
            <p className="my-5 md:text-base text-sm">
              An educational programme, held prior to the weekend of the
              festival, will be organized in partnership with the English
              department of the Cadi Ayyad University and the University
              Mohammed VI Polytechnic (UM6P) with the aim of giving Moroccan
              English university students a chance to learn from, meet and be
              inspired by published authors in the English language.
            </p>
            <p className="my-5 md:text-base text-sm">
              There will also be a visual arts program in partnership with the
              Ecole Supérieure des Arts Visuels (ESAV) in Marrakech.
            </p>
            <p className="my-5 md:text-base text-sm">
              The invited authors have all published books in English that have
              been inspired by Marrakech, Morocco or North Africa.
            </p>
            <p className="my-5 md:text-base text-sm">
              A pop-up book market near one of the festival venues will sell
              books in English.
            </p>
          </div>
        </div>
        {/* footer */}
        <PartnerWidget />
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default AboutScreen;
